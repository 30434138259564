import React from 'react';
import { initializeApp } from 'firebase/app';
import mixpanel from 'utils/mixpanelConfig';
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  // onAuthStateChanged,
  // signInWithPopup,
  // GoogleAuthProvider,
  // FacebookAuthProvider,
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendSignInLinkToEmail,
  // sendEmailVerification,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  // RecaptchaVerifier,
  // signInWithPhoneNumber,
  // PhoneAuthProvider,
  // linkWithPhoneNumber,
  // unlink,
} from 'firebase/auth';

import { firebaseConfig } from 'utils/configs';
import api from 'utils/api';

const { createContext, useContext, useState, useEffect } = React;

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

export const FirebaseAuthContext = createContext({});
FirebaseAuthContext.displayName = 'FirebaseAuth';

export const FirebaseAuthProvider = (props) => {
  const { children } = props;

  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const getUser = () => {
    return auth.currentUser;
  };

  const reloadUser = async () => {
    if (auth.currentUser) {
      await auth.currentUser.reload();
      setCurrentUser(auth.currentUser);
    }
  };

  const signOut = async () => {
    await auth.signOut();
    mixpanel.reset();
  };

  const emailSignUp = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const emailSignIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const isEmailLink = () => {
    return isSignInWithEmailLink(auth, window.location.href);
  };

  const emailLinkSignIn = (email) => {
    return signInWithEmailLink(auth, email, window.location.href);
  };

  const verifyEmailAddress = () => {
    // return sendEmailVerification(auth.currentUser, {
    //   url: `${window.location.origin}`,
    // });
    return api.request({
      url: `/api/v2/sendEmailVerification`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.currentUser.accessToken}`,
      },
      body: {
        redirectUrl: `${window.location.origin}`,
      },
    });
  };

  const sendEmailSignInLink = (email) => {
    return sendSignInLinkToEmail(auth, email, {
      url: `${window.location.origin}/email`,
      handleCodeInApp: true,
    });
  };

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    return auth.signInWithPopup(provider);
  };

  const fbSignIn = () => {
    const provider = new FacebookAuthProvider();
    return auth.signInWithPopup(provider);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setCurrentUser(authUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <FirebaseAuthContext.Provider
      value={{
        auth,
        currentUser,
        loading,
        getUser,
        reloadUser,
        emailSignIn,
        emailSignUp,
        verifyEmailAddress,
        sendEmailSignInLink,
        isEmailLink,
        emailLinkSignIn,
        googleSignIn,
        fbSignIn,
        signOut,
      }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export const useFirebaseAuth = () => {
  return useContext(FirebaseAuthContext);
};
