import React from 'react';
import _ from 'lodash';
import useSWR from 'swr';
import { swrFetcher } from 'utils/api';

const { createContext, useContext, useState, useEffect } = React;

export const AppLaunchContext = createContext({});
AppLaunchContext.displayName = 'AppLaunch';

export const AppLaunchProvider = (props) => {
  const { children } = props;

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    '/api/v2/appLaunch',
    (url) => swrFetcher(url)
  );

  const categoryTree = _.get(data, 'category_tree', []) || {};
  const notifications = _.get(data, 'notifications', []) || [];
  const activityTags = _.get(data, 'activity_tags', []) || [];

  const firstCategoryMap = _.reduce(
    categoryTree,
    (acc, firstCategory) => {
      acc[firstCategory.id] = firstCategory;
      return acc;
    },
    {}
  );

  const secondCategoryMap = _.reduce(
    categoryTree,
    (acc, firstCategory) => {
      const secondCategories = firstCategory.second_categories;
      _.forEach(secondCategories, (secondCategory) => {
        acc[secondCategory.id] = secondCategory;
      });
      return acc;
    },
    {}
  );

  const activityTagMap = _.reduce(
    activityTags,
    (acc, tag) => {
      acc[tag.id] = tag;
      return acc;
    },
    {}
  );

  return (
    <AppLaunchContext.Provider
      value={{
        isLoading,
        categoryTree,
        firstCategoryMap,
        secondCategoryMap,
        activityTagMap,
        notifications,
      }}
    >
      {children}
    </AppLaunchContext.Provider>
  );
};

export const useAppLaunch = () => {
  return useContext(AppLaunchContext);
};
